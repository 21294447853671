class LoginService {
  constructor() {
    // this.token = 'tokenjob';
  }
  resources = () => ({
    login: {
      uri: `/v1/login`,
      method: ['post']
    },
    empresas: {
      uri: `/ext/empresas`,
      method: ['get'],
      auth: {
        username: 'HTECUSER',
        password: 'htecurser*_*2020giplan'
      }
    },
    proyeccionMo: {
      uri: `/v1/presupuesto/listar-proyeccion-mo`,
      method: ['get']
    },
    configuraciones: {
      uri: `/v1/autorizacion/listar-configuracion-usuario`,
      method: ['get']
    },
    cerrarSesion: {
      uri: `/v1/logout`,
      method: ['get']
    }
  });
}

export default LoginService;
