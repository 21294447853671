<template>
  <v-row>
    <v-col cols="12" lg="8" xl="8" class="background-login d-none d-md-flex align-center justify-center"> </v-col>
    <v-col cols="12" lg="4" xl="4" class="d-flex align-center">
      <v-container>
        <div class="">
          <v-row class="justify-center">
            <v-col cols="10" lg="10" xl="10">
              <div style="text-align:center">
                <div style="max-height:100px !important" class="mt-6 mb-6">
                  <img width="100" src="@/assets/images/innovahtec.png" />
                </div>
              </div>
              <v-form ref="form" v-model="valid" lazy-validation action="/">
                <v-text-field
                  v-model="usuario"
                  :rules="usuarioRules"
                  label="Usuario"
                  class="mt-4"
                  required
                  outlined
                  uppercase
                  prepend-inner-icon="fas fa-id-badge"
                  @blur="upperCase"
                >
                </v-text-field>
                <v-text-field
                  v-model="clave"
                  :rules="claveRules"
                  label="Contraseña"
                  required
                  outlined
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <!-- <v-select
                  :items="data"
                  v-model="empresa"
                  :rules="empresaRules"
                  label="Empresa"
                  outlined
                  required
                  prepend-inner-icon="fas fa-building"
                ></v-select> -->
                <v-btn
                  :loading="loading"
                  :disabled="!valid || loading"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  @click="submit"
                  >Iniciar Sesión</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import LoginService from '../services/LoginService';
import Swal from 'sweetalert2';
//import fire from '@/fire';
//import { decryptAES, decryptData, encryptAES } from '@/components4x/utils/utils4x';
import { decryptAES, encryptAES } from '@/components4x/utils/utils4x';

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    loginService: null,
    valid: true,
    clave: '',
    empresa: '',
    show1: false,
    usuario: '',
    claveRules: [(v) => !!v || 'Contraseña es requerida.'],
    usuarioRules: [(v) => !!v || 'Usuario es requerido.'],
    empresaRules: [(v) => !!v || 'Empresa es requerida'],
    checkbox: false,
    data: []
  }),
  methods: {
    upperCase() {
      this.usuario = this.usuario.toUpperCase();
    },
    async iniciaData() {
      const res = await this.loginService.get().execResource('empresas', {
        api_token: 'BBBBBBBBBBB*cXXVOkdQgiJBJBBCKF53UZkX'
      });
      res.forEach((element) => {
        this.data.push({ text: element.razonsocial, value: element.idempresa });
      });
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        const res = await this.loginService.post().execResource('login', {
          usuario: this.usuario,
          clave: this.clave
        });

        if (!res.status) {
          await Swal.fire({
            icon: 'error',
            title: res.message,
            confirmButtonText: `OK`
          });
          this.loading = false;
          return;
        }
        if (parseInt(res.data.user.dias) <= 15) {
          if (res.data.user.dias <= 0) {
            await Swal.fire({
              icon: 'error',
              title: `Su licencia ha vencido, no cuenta con acceso al sistema...`,
              confirmButtonText: `OK`
            });
            this.loading = false;
            return;
          }
          await Swal.fire({
            icon: 'warning',
            title: `Quedan ${res.data.user.dias} dias de prueba...`,
            confirmButtonText: `OK`
          });
        }
        await this.setLocal(res);
        await this.loadSettings();
        //await this.loadPermissions();
        await this.cargarProyeccionMO();
        this.loading = false;
        await this.$router.push({ path: '/inicio' });
      }
    },
    async setLocal(res) {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('emp', encryptAES('001')); // Empresa
      localStorage.setItem('user', encryptAES(this.usuario));
      localStorage.setItem('password', encryptAES(this.clave));
      //-----------------------------------------------
      localStorage.setItem('nc4rhtcc976', res.data.user.nombresresponsable);
      localStorage.setItem('k3rtxqdp234', res.data.user.idarea);
      localStorage.setItem('oq1rcizm635', res.data.user.idsubarea);
      localStorage.setItem('iow5csjr398', res.data.user.remail);
      localStorage.setItem('lrd5qwlr674', res.data.user.idresponsable);
    },
    async loadPermissions() {
      /*const id = localStorage.getItem('lrd5qwlr674');
      if (id === null) return;

      const role = await this.$store.getters.getRole;
      if (role === '') return;

      const ref = fire.firestore().collection('modules_auth');

      console.log(ref);
      const response = await ref.where('roles', 'array-contains', role).get();

      // console.log('response', response.docs);

      if (response.empty) return;

      const permissions = [];

      response.forEach((doc) => {
        permissions.push(doc.data().code);
      });

      await this.$store.dispatch('updatePermission', permissions);*/
    },
    async cerrarSesion() {
      const res = await this.loginService.get().execResource('cerrarSesion');
      if (res.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('lrd5qwlr674');
        localStorage.removeItem('iow5csjr398');
        localStorage.removeItem('nc4rhtcc976');
        localStorage.removeItem('k3rtxqdp234');
        localStorage.removeItem('oq1rcizm635');
        await this.$store.dispatch('updateReset');
        this.$router.push({ path: '/auth/login' });
      }
    },
    async loadSettings() {
      /*const id = localStorage.getItem('lrd5qwlr674');
      if (id === null) return;

      const decryptId = decryptData(id);

      const config = await fire
        .firestore()
        .collection('users')
        .doc(decryptId)
        .get();

      if (!config.exists) return;

      this.$vuetify.theme.dark = config.data().themedark;
      this.$store.commit('SET_SIDEBAR_COLOR', config.data().sidebarcolor);
      this.$store.commit('SET_THEME_COLOR', config.data().themecolor);
      await this.$store.dispatch('updateRole', config.data().role);
      await this.$store.dispatch('updateProfile', config.data().profile);*/

      //---------------------------------------------------------------
      const res = await this.loginService.get().execResource('configuraciones');
      if (res[0].activo == 1) {
        this.$vuetify.theme.dark = Number(res[0].themedark);
        this.$store.commit('SET_SIDEBAR_COLOR', res[0].sidebarcolor);
        this.$store.commit('SET_THEME_COLOR', res[0].themecolor);
        await this.$store.dispatch('updateProfile', res[0].permisos);
        await this.$store.dispatch('updateRole', res[0].role);
        await this.$store.dispatch('updatePermission', res[0].module_code);
      } else {
        this.cerrarSesion();
      }
    },
    async cargarProyeccionMO() {
      console.log('cargar proyeccion mo');
      const res = await this.loginService.get().execResource('proyeccionMo');
      await this.$store.dispatch('cargarProyeccionMO', res);
      console.log(this.$store, 'storeeee');
    }
  },
  async created() {
    this.usuario = decryptAES(localStorage.getItem('user'));
    this.clave = decryptAES(localStorage.getItem('password'));
    this.empresa = decryptAES(localStorage.getItem('emp'));
  },
  async mounted() {
    if (localStorage.getItem('token') !== null) {
      await this.$router.push({ path: '/inicio' });
    } else {
      this.loginService = this.$httpService(new LoginService(), this);
      await this.iniciaData();
    }
  }
};
</script>
<style lang="css">
.background-login {
  background: url('../../../assets/images/logo_gestion.jpg') no-repeat;
  background-size: cover;
  /*background: url('../../../assets/images/background/bg-login.jpg') no-repeat fixed center;*/
  /*background-size: contain;*/
  /* background-position: initial;*/
}
</style>
